import React from 'react';
import PropTypes from 'prop-types';
import { useCubeQuery } from '@cubejs-client/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import palette from '../theme/palette';
import moment from 'moment';
const COLORS_SERIES = [palette.primary.main, palette.primary.light, palette.primary.dark, palette.secondary.main, palette.secondary.light];

const TypeToChartComponent = {
  line: ({ resultSet }) => {
    const data = {
      labels: resultSet.categories().map((c, i) => i % Math.floor(resultSet.categories().length / 5) === 0 ? moment(c.x).format('DD/MM') : ""),
      datasets: resultSet.series().map((s, index) => ({
        label: s.title === "true, Harvestdata Total Round Hours" ? "Billable" : "Non-Billable",
        data: s.series.map((r) => r.value),
        borderColor: COLORS_SERIES[index],
        backgroundColor: COLORS_SERIES[index]
      })),
    };
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
          }
        }
      },
      elements: {
        point: {
          pointStyle: 'rectRot'
        },
        line: {
          borderWidth: 1.5
        }
      },
      plugins: {
        legend: {
          display: true,
          align: 'end',
          labels: {
            boxWidth: 10,
            color: palette.text.secondary
          }
        }
      },
      animations: {
        tension: {
          duration: 1000,
          easing: 'linear',
          from: 1,
          to: 0.5,
          loop: false
        }
      }
    };
    return <Line data={data} options={options} />;
  },
  bar: ({ resultSet }) => {
    const data = {
      labels: resultSet.categories().map((c) => c.x),
      datasets: resultSet.series().map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        backgroundColor: COLORS_SERIES[index],
        hoverBackgroundColor: COLORS_SERIES[index],
        fill: false,
        skipNull: true,
        datalabels: {
          anchor: 'end',
          align: 'right',
          offset: "5",
          color: palette.text.primary,
          textStrokeWidth: 0.5,
          formatter: function(value, context) {
            return value + " h";
          }
        }
      })),
    };
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y',
      barThickness: '14',
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            offset: true
          },
          ticks: {
            color: palette.text.secondary,
            callback: function(value, index, values) {
              return this.getLabelForValue(value) + " h"
            }
          }
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            color: palette.text.secondary,
            callback: function(value, index, values) {
              return this.getLabelForValue(value) === "∅" ? "Other" : this.getLabelForValue(value).replace('Billable - ','')
            }
          }
        }
      },
    };
    return <Bar data={data} options={options} plugins={[ChartDataLabels]} />;
  },
  area: ({ resultSet }) => {
    const data = {
      labels: resultSet.categories().map((c) => c.x),
      datasets: resultSet.series().map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        backgroundColor: COLORS_SERIES[index],
      })),
    };
    const options = {
      scales: {
        yAxes: [
          {
            stacked: true,
          },
        ],
      },
    };
    return <Line data={data} options={options} />;
  },
  pie: ({ resultSet }) => {
    const data = {
      labels: resultSet.categories().map((c) => c.x),
      datasets: resultSet.series().map((s) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        backgroundColor: COLORS_SERIES,
        hoverBackgroundColor: COLORS_SERIES,
      })),
    };
    const options = {
      plugins: {
        legend: {
            display: false
        }
      }
    };
    return <Doughnut data={data} options={options} />;
  },
  number: ({ resultSet }) => (
    <Typography
      variant="h4"
      style={{
        textAlign: 'center',
      }}
    >
      {resultSet.seriesNames().map((s) => resultSet.totalRow()[s.key])}
    </Typography>
  ),
  table: ({ resultSet }) => (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          {resultSet.tableColumns().map((c) => (
            <TableCell key={c.key}>{c.title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {resultSet.tablePivot().map((row, index) => (
          <TableRow key={index}>
            {resultSet.tableColumns().map((c) => (
              <TableCell key={c.key}>{row[c.key]}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ),
};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderChart = (Component) => ({ resultSet, error, ...props }) =>
  (resultSet && <Component resultSet={resultSet} {...props} />) ||
  (error && error.toString()) || <CircularProgress color="secondary" />;

const ChartRenderer = ({ vizState = {} }) => {
  const { query, chartType, ...options } = vizState;
  const component = TypeToMemoChartComponent[chartType];
  const renderProps = useCubeQuery(query);
  return component && renderChart(component)({ ...options, ...renderProps });
};

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  cubejsApi: PropTypes.object,
};
export default ChartRenderer;
