import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import { useCubeQuery } from '@cubejs-client/react';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    display: 'flex',
  },
  title: {
    fontWeight: 400,
    fontSize: 16
  },
  subtitle: {
    fontWeight: 200,
    fontSize: 14
  },
  progress: {
    marginTop: theme.spacing(3),
    height: '8px',
    borderRadius: '10px',
  }
}));

const KPIChart = (props) => {
  const classes = useStyles();
  const { className, title, progress, query, difference, duration, ...rest } = props;
  const { resultSet, error, isLoading } = useCubeQuery(query);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="secondary" />
      </div>
    );
  }
  if (error) {
    return <pre>{(error).toString()}</pre>;
  }
  if (!resultSet) {
    return null
  }
  if (resultSet) {
    const dmpgClient = localStorage.getItem("CognitoIdentityServiceProvider.client");
    let billValue = 0, nBillValue = 0;
    resultSet.tablePivot().forEach(function(s) {
      if (s['Harvestdata.billable']) return billValue = Number(s['Harvestdata.totalHours'])
      return nBillValue = Number(s['Harvestdata.totalHours'])
    });
    let fullValue = (billValue + nBillValue).toFixed(2);
    return (
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
            <Grid item xs={12}
            style={{
              textAlign: "left",
              paddingBottom: 20
            }}>
              <Typography className={classes.title} color="textPrimary" gutterBottom variant="body2">
                Total Hours
              </Typography>
              <Typography variant="h1" 
              style={{
                textAlign: "center",
                paddingTop: dmpgClient ? 30 : 10
              }}>
                {fullValue}
              </Typography>
            </Grid>
            <Divider variant="fullWidth" />
            { dmpgClient ? "" :
              <Grid container>
                <Grid item xs={6}
                style={{
                  textAlign: "left",
                  paddingTop: 10
                }}>
                  <Typography className={classes.subtitle} color="textSecondary" gutterBottom variant="body2">
                    Billable Hours
                  </Typography>
                  <Typography variant="h4">
                    {billValue}
                  </Typography>
                </Grid>
                <Grid item xs={6}
                style={{
                  textAlign: "left",
                  paddingTop: 10
                }}>
                  <Typography className={classes.subtitle} color="textSecondary" gutterBottom variant="body2">
                    Non-Billable Hours
                  </Typography>
                  <Typography variant="h4">
                    {nBillValue}
                  </Typography>
                </Grid>
              </Grid>
            }
        </CardContent>
      </Card>
    );
  }
};

KPIChart.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export default KPIChart;