import palette from "../theme/palette";

export const DoughnutOptions = {
  plugins: {
    legend: {
      display: false,
      //align: "start",
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: 'rect',
      },
    },
    htmlLegend: {
      // ID of the container to put the legend in
      containerID: 'legend-container',
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  cutout: "70%",
  layout: { padding: 0 },
  tooltips: {
    enabled: true,
    mode: "index",
    intersect: false,
    borderWidth: 0,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  }
};