import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useCubeQuery } from "@cubejs-client/react";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress, Card } from "@material-ui/core";
import { DataGrid } from '@mui/x-data-grid';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  }
}));

const TableComponent = props => {

  const { className, sorting, setSorting, query, cubejsApi, ...rest } = props;
const classes = useStyles();

const [pageSize, setPageSize] = React.useState(10);
const { resultSet, error, isLoading } = useCubeQuery(query, { cubejsApi });
  if (isLoading) {
    return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress color="secondary" /></div>;
  }
  if (error) {
    return <pre>{error.toString()}</pre>;
  }
  if (resultSet) {
    const dmpgClient = localStorage.getItem("CognitoIdentityServiceProvider.client");
    let entries = resultSet.tablePivot();
    let tableHeaders = [
      {
        field: "id",
        headerName: "ID",
        hide: true
      },
      {
        field: "taskName",
        headerName: "Task name",
        width: 450,
      },
      {
        field: "projectName",
        headerName: "Project",
        width: dmpgClient ? 390 : 320,
      },
      {
        field: "requestType",
        headerName: "Request Type",
        width: dmpgClient ? 240 : 150,
      },
      {
        field: "billable",
        headerName: "Billable?",
        width: 160,
        type: 'boolean',
        hide: dmpgClient ? true : false
      },
      {
        field: "hours",
        headerName: "Hours",
        type: "number",
        headerAlign: "left",
        align: "center",
        sortable: true,
        width: 120,
      }
    ];
    let anything = [];
    entries.map((obj, index) => (
      anything.push({
        id: index,
        taskName: obj["Harvestpodio.ticketName"] || obj["Harvestdata.cleanNotes"],
        projectName: obj["Harvestdata.projectName"],
        requestType: (obj["Harvestpodio.requestType"] ? obj["Harvestpodio.requestType"] : obj["Harvestdata.taskName"] && obj["Harvestdata.taskName"].replace("Billable - ","")) || "Other",
        billable: obj["Harvestdata.billable"],
        hours: Number(obj["Harvestdata.totalHours"]).toFixed(2)
      })
    ));
    return (
      <Card
        {...rest}
        padding={"0"}
        className={clsx(classes.root, className)}
      >
        <DataGrid
          rows={anything}
          columns={tableHeaders}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          pagination
          autoHeight
        />
      </Card>
    );
  } else {
    return null
  }
};

TableComponent.propTypes = {
  className: PropTypes.string,
  query: PropTypes.object.isRequired
};

export default TableComponent;