import React from 'react';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Card, CardActions, CardContent, Typography } from '@material-ui/core';
import Amplify, { Auth, Hub } from 'aws-amplify';
import GoogleButton from 'react-google-button'
import 'typeface-roboto'

import './body.css';
import theme from './theme';
import palette from "./theme/palette";
import Header from './components/Header';
import aws_exports from './aws-exports';

const API_URL = 'https://clients-api.dmpgteam.com';
const cubejsApi = cubejs(
  async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
  { apiUrl: `${API_URL}/cubejs-api/v1` }
);
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    margin: '-8px',
    backgroundColor: palette.primary.background,
  },
  login: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(4),
    maxWidth: 400,
  }
}));
const redirectURL = document.location.protocol + "//" + document.location.host + "/";
aws_exports["oauth"]["redirectSignIn"] = redirectURL;
aws_exports["oauth"]["redirectSignOut"] = redirectURL;
Amplify.configure(aws_exports);

if (document.location.hash.indexOf('/client/') !== -1) {
  async function signIn() {
    try {
      const username = document.location.hash.split('/')[2];
      const password = document.location.hash.split('/')[3];
      await Auth.signIn(username, password);
      localStorage.setItem("CognitoIdentityServiceProvider.client",username)
    } catch (error) {
      console.log('error signing in', error);
    }
  }
  Auth.currentAuthenticatedUser().catch(() => {
    signIn();
  });
} else if (document.location.hash.indexOf('/client') === -1){
  Auth.currentAuthenticatedUser().then(userData => {
    if (userData.username && userData.username.indexOf("Google_") === -1){
      localStorage.removeItem("CognitoIdentityServiceProvider.client");
      Auth.signOut();
    }
  }).catch(() => null);
}

const AppLayout = ({ children, user }) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Header user={user} />
        <div>{children}</div>
      </div>
    </ThemeProvider>
  );
};

const App = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const classes = useStyles();

  React.useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        default:
          break;
      }
    });

    getUser().then(userData => setUser(userData));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch((e) => console.log(e));
  }
  return (
    <div>
      {user ? (
        <CubeProvider cubejsApi={cubejsApi}>
          <AppLayout user={user}>{children}</AppLayout>
        </CubeProvider>
      ) : (
        <AppLayout>
          <div className={classes.login}>
          <Card xs={350}>
            <CardContent style={{display:'flex', justifyContent:'center' }}>
            <Typography variant="h3" color="secondary" >
              Harvest Billing Tool
            </Typography> 
            </CardContent>
            <CardActions style={{display:'flex', justifyContent:'center' }}>
              <GoogleButton onClick={() => Auth.federatedSignIn({provider: "Google"})} />
            </CardActions>
          </Card>
          </div>
        </AppLayout>
      )}
    </div>
  );
}

export default App;