import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ContactsIcon from '@material-ui/icons/Contacts';
import palette from "../theme/palette";

const useStyles = makeStyles(theme => ({
  root: {
    verticalAlign: "middle"
  },
  formControl: {
    margin: `auto ${theme.spacing(2)}px`,
    minWidth: 150,
  },
  input: {
    color: palette.text.secondary
  }
}));

const ClientSelector = ({ clients, clientFilter, setClientFilter }) => {
  const classes = useStyles();
  const [optionValue, setOptionValue] = React.useState(clientFilter);
  
  const handleChangeSelect = ({ props }) => {
    localStorage.setItem('clientIndex', props.value);
    setOptionValue(props.value);
    setClientFilter(props.value);
  };

  return (
    <div
      className={classes.root}
    >
      <ContactsIcon 
        color="primary"
        style={{
          height:"100%"
        }}
      />
      <FormControl className={classes.formControl}>
        <Select className={classes.input} value={optionValue} onChange={(e,value) => {handleChangeSelect(value)}} aria-label="ant example">
          {clients.map((item, index) => (<MenuItem key={`${item}___${index}`} value={index}>{item}</MenuItem>))}
        </Select>
      </FormControl>
      <Typography className={classes.padding} />
    </div>
  );
};

export default ClientSelector;