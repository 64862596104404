import palette from '../palette';

const MuiDataGrid = {
  root: {
    border: `1px solid #f0f0f0`,
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#fafafa',
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid #f0f0f0`,
    },
    '& .MuiDataGrid-cell': {
      color: palette.text.primary,
      fontSize: 14,
      WebkitFontSmoothing: 'auto',
      '& .MuiPaginationItem-root': {
        borderRadius: 0,
      }
    }
  }
};

export default MuiDataGrid;