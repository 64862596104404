import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Divider, Typography, Grid } from '@material-ui/core';
import { useCubeQuery } from '@cubejs-client/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DoughnutOptions } from '../helpers/DoughnutOptions.js';
import palette from '../theme/palette';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  chartContainer: {
    marginTop: theme.spacing(2),
    position: 'relative',
    height: '150px',
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',

  },
  status: {
    width: "100%",
    textAlign: 'left',
    padding: 0,
  },
  title: {
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1),
    fontWeight: 300
  },
  statusIcon: {
    color: theme.palette.icon,
  },
}));

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('div');

  if (!listContainer) {
    listContainer = document.createElement('div');
    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item, i) => {
      const container = document.createElement('div');
      container.className = 'MuiGrid-root MuiGrid-container';
      container.style.alignItems = "center";
      container.style.minHeight = "30px"
      container.style.marginBottom = "5px"

      container.onclick = () => {
        const {type} = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.className = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1";
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.height = '15px';
      boxSpan.style.marginRight = '10px';

      //Value container
      const textContainer = document.createElement('div');
      textContainer.className = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-7";

      // Text
      const textContainerText = document.createElement('p');
      textContainerText.className = "MuiTypography-root makeStyles-title-25 MuiTypography-body2";
      textContainerText.style.textDecoration = item.hidden ? 'line-through' : '';
      textContainerText.style.padding = 0
      const text = document.createTextNode(item.text);
      textContainerText.appendChild(text);

      textContainer.appendChild(textContainerText)

      //Value container
      const valueContainer = document.createElement('div');
      valueContainer.className = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-3";
      valueContainer.style.paddingLeft = "5px"

      //Value
      const valueContainerText = document.createElement('h6');
      valueContainerText.className = "MuiTypography-root MuiTypography-h6";
      valueContainerText.style.textDecoration = item.hidden ? 'line-through' : '';
      const valueText = document.createTextNode(chart.data.datasets[0].data[i] + " h");
      valueContainerText.appendChild(valueText);

      valueContainer.appendChild(valueContainerText)

      container.appendChild(boxSpan);
      container.appendChild(textContainer);
      container.appendChild(valueContainer);

      ul.appendChild(container);
    });
  }
};

const DoughnutChart = (props) => {
  const { className, query, ...rest } = props;

  const classes = useStyles();

  const { resultSet, error, isLoading } = useCubeQuery(query);
  if (isLoading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="secondary" />
      </div>
    );
  }
  if (error) {
    return <pre>{error.toString()}</pre>;
  }
  if (!resultSet) {
    return null
  }
  if (resultSet) {
    const COLORS_SERIES = [palette.primary.main, palette.primary.dark, palette.primary.light, palette.secondary.light, palette.secondary.main];
    const data = {
      labels: resultSet.categories().map((c) => c.x.length <= 1 ? "Other" : c.x),
      datasets: resultSet.series().map((s) => ({
        label: s.title.length <= 1 ? "Other" : s.title,
        data: s.series.map((r) => r.value),
        backgroundColor: COLORS_SERIES,
        hoverBackgroundColor: COLORS_SERIES,
      })),
    };

    return (
      <Card {...rest} className={clsx(classes.root, className)}>
        <Typography className={classes.title} color="textPrimary" gutterBottom variant="body2"
              style={{
                paddingTop: 20,
                paddingLeft: 10,
                paddingBottom: 12,
                fontSize: 16,
                color: palette.text.primary,
                fontWeight: 400
              }}>
                Project type
              </Typography>
        <Divider />
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <div className={classes.chartContainer}>
                <Doughnut data={data} options={DoughnutOptions} plugins={[htmlLegendPlugin]}/>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.stats}>
                <div className={classes.status}>
                  <div id="legend-container"></div>
                  {/* {resultSet.series()[0] && resultSet.series()[0].series.map((status) => (
                    <Grid container key={status.x}>
                      <Grid item xs={8}>
                        <Typography variant="body1" className={classes.title}>
                          {status.x.length <= 1 ? "Other" : status.x}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6">{status.value} h</Typography>
                      </Grid>
                    </Grid>
                  ))} */}
                  </div>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
};

DoughnutChart.propTypes = {
  className: PropTypes.string,
};

export default DoughnutChart;