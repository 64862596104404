import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, Divider } from "@material-ui/core";
import palette from '../theme/palette';
import ChartRenderer from './ChartRenderer'



const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    position: "relative",
    padding: "19px 0 0 0",
    height: "200px"
  }
}));

const BarChart = props => {
  const { className, query, ...rest } = props;
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Typography className={classes.title} color="textPrimary" gutterBottom variant="body2"
              style={{
                paddingTop: 20,
                paddingLeft: 10,
                paddingBottom: 12,
                fontSize: 16,
                color: palette.text.primary,
                fontWeight: 400
              }}>
                Request type
              </Typography>
        <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <ChartRenderer vizState={{ query, chartType: 'bar' }}/>
        </div>
      </CardContent>
    </Card>
  )
};

BarChart.propTypes = {
  className: PropTypes.string
};

export default BarChart;