import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { DateRange } from 'react-date-range';
import {useState} from 'react'
import FormHelperText from '@material-ui/core/FormHelperText';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import palette from "../theme/palette";
import moment from 'moment';


const useStyles = makeStyles(theme => ({
  root: {
    verticalAlign: "middle"
  },
  formControl: {
    margin: `auto ${theme.spacing(2)}px`,
    minWidth: 150,
  },
  input: {
    color: palette.text.secondary
  }
}));

function DateDialog({ onClose, selectedValue, open, value: valueProp }) {
  const currDate = new Date();
  const handleClose = () => {
    onClose(selectedValue);
  };
  const [dateState, setDateState] = useState([
    {
      startDate: Array.isArray(valueProp) ? new Date(valueProp[0]) : new Date(currDate.getFullYear(),currDate.getMonth(),currDate.getDate()-31),
      endDate: Array.isArray(valueProp) ? new Date(valueProp[1]) : new Date(currDate.getFullYear(),currDate.getMonth(),currDate.getDate()-1),
      key: 'selection'
    }
  ]);

  const handleOk = () => {
    onClose([dateState[0].startDate.toISOString(),dateState[0].endDate.toISOString()]);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="date-range-dialog" open={open} maxWidth={false}>
      <DialogContent dividers>
      <DateRange
        editableDateInputs={true}
        onChange={item => setDateState([item.selection])}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={dateState}
        direction="horizontal"
        maxDate={new Date()}
      />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const DateSelector = props => {
  const classes = useStyles();
  const { dateRange, setDateRange } = props;
  const [openDateRange, setOpenDateRange] = React.useState(false);
  const dateRanges = [
      "Yesterday",
      "This week",
      "Last week",
      "This month",
      "Last month",
      "Last 7 days",
      "Last 30 days"
    ];
  const [dateRangeValue, setDateRangeValue] = React.useState(dateRanges.indexOf(dateRange) === -1 ? Array.isArray(dateRange) ? dateRange : dateRange.split(',') : "");
  const [dateRangeOption, setDateRangeOption] = React.useState(dateRanges.indexOf(dateRange) >= 0 ? dateRange : "Custom date");

  const handleDateRangeSelect = ({ props }) => {
    if (props.value === "Custom date") {
      setOpenDateRange(true)
    } else {
      setDateRangeValue("");
      localStorage.setItem('lastDate', props.value);
      setDateRange(props.value);
      setDateRangeOption(props.value);
    }
  };

  const handleClose = (value) => {
    if (value) {
      localStorage.setItem('lastDate', value);
      setDateRange(value);
      setDateRangeValue(value);
      setDateRangeOption("Custom date");
    }
    setOpenDateRange(false);
  };

  return (
    <div
      className={classes.root}
    >
      <CalendarTodayIcon 
          color="primary"
          style={{
            height:"100%"
          }}
          />
      <FormControl className={classes.formControl}>
        <Select className={classes.input} value={dateRangeOption} onChange={(e,value) => {handleDateRangeSelect(value)}} aria-label="ant example">
          {dateRanges.map((item) => (<MenuItem key={`${item}`} value={item}>{item}</MenuItem>))}
          <MenuItem key="Custom date" onClick={() => setOpenDateRange(true)} value="Custom date">Custom date</MenuItem>
        </Select>
        <FormHelperText>{dateRangeValue && `${moment(dateRangeValue[0]).format('DD/MM/YYYY')} - ${moment(dateRangeValue[1]).format('DD/MM/YYYY')}`}</FormHelperText>
      </FormControl>
      <DateDialog open={openDateRange} value={dateRangeValue} onClose={handleClose} setDateRangeValue={setDateRangeValue} />
    </div>
  );
};

export default DateSelector;