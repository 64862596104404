import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTypography from './MuiTypography';
import MuiInput from './MuiInput';
import MuiCard from './MuiCard';
import MuiDataGrid from './MuiDataGrid';
import MuiFormControlLabel from './MuiFormControlLabel';

const index = {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiDataGrid,
  MuiTypography,
  MuiInput,
  MuiCard,
  MuiFormControlLabel
};
export default index;
