import React from "react";
import { withRouter } from "react-router";
import AppBar from "@material-ui/core/AppBar";
import { Auth } from 'aws-amplify';
import palette from "../theme/palette";

import {  Container } from "@material-ui/core";

const Header = ({ user }) => {
  return (
    <AppBar
      position="static"
      color="secondary"
      style={{
        borderBottom: `3px solid ${palette.primary.main}`,
      }}
    >
      <Container
        style={{
          width: "1200px",
          height: "50px",
          backgroundImage: 'url("/images/dmpg_logo_dark.png")',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left",
          backgroundSize: "auto 50%",
          display: "flex", 
          justifyContent: "flex-end",
          alignItems: "center",
          paddingRight: 0
        }}
      >
        {user && user.username && user.username.indexOf('Google_') !== -1 ? <button style={{height: "30px", backgroundColor: palette.primary.main }} onClick={() => Auth.signOut().then(document.location="http://localhost:3000/")}>Sign Out</button> : ""}
      </Container>
    </AppBar>
  );
};

export default withRouter(Header);
