import React from "react";
import { CircularProgress, FormControlLabel, Grid, Paper, Switch, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import KPIChart from "../components/KPIChart";
import DoughnutChart from "../components/DoughnutChart.js";
import Table from "../components/Table.js";
import { useCubeQuery } from "@cubejs-client/react";
import DateSelector from "../components/DateSelector";
import ClientSelector from "../components/ClientSelector";
import LineChart from "../components/LineChart";
import BarChart from "../components/BarChart";

const useStyles = makeStyles((theme) => {
  return({
  root: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(4),
    maxWidth: 1200,
  },
  header: {
    margin: `${theme.spacing(1)}px auto`,
    borderBottom: "5px solid red",
  },
  paper: {
    margin: `${theme.spacing(2)}px 0`,
    textAlign: "left",
    height: 200
  },
  logo: {
    maxWidth: "80%",
    maxHeight: "80%"
  },
  loading: {
    width: "150px"
  },
})});
const lastDate = localStorage.getItem('lastDate');
const Dashboard = (props) => {
  const classes = useStyles();
  let [clientFilter, setClientFilter] = React.useState(localStorage.getItem('clientIndex') || 0);
  const [dateRange, setDateRange] = React.useState(lastDate ? (lastDate.indexOf(',') !== -1 ? lastDate.split(',') : lastDate) : "Last month");
  const [sorting, setSorting] = React.useState([
    "Harvestdata.totalRoundHours",
    "desc",
  ]);
  const [billable, setBillable] = React.useState(localStorage.getItem('isBillable') ? JSON.parse(localStorage.getItem('isBillable').toLowerCase()) : true);
  const handleBillable = (event) =>{
    localStorage.setItem('isBillable', event.target.checked);
    setBillable(event.target.checked);
  }
  const { resultSet, isLoading, error, progress } = useCubeQuery({
    dimensions: ["Harvestdata.clientName"],
  });
  if (isLoading) {
    return (
      <div>
        {(progress && progress.stage && progress.stage.stage) || <div style={{paddingTop:"200px"}} className={classes.root} ><Grid container spacing={4} justifyContent="center"><CircularProgress className={classes.loading} /></Grid></div>}
      </div>
    );
  }

  if (error) {
    return <div>{error.toString()}</div>;
  }

  if (!resultSet) {
    return null;
  }
  const dmpgClient = localStorage.getItem("CognitoIdentityServiceProvider.client");

  const clients = resultSet
    .tablePivot()
    .map((client) => client["Harvestdata.clientName"]);
  if (Number(clientFilter) > clients.length-1) {
    localStorage.setItem('clientIndex',0);
    clientFilter = 0;
  }

  const entriesTableQuery = {
    order: {
      [`${sorting[0]}`]: sorting[1],
    },
    timeDimensions: [
      {
        dimension: "Harvestdata.spentDate",
        dateRange: dateRange,
      },
    ],
    dimensions: ["Harvestpodio.ticketName", (clients[clientFilter].toLowerCase() === "sis/phd" ?  "Harvestdata.taskName" : "Harvestpodio.requestType"), "Harvestdata.cleanNotes", "Harvestdata.billable", "Harvestdata.projectName"],
    measures: ["Harvestdata.totalHours"],
    filters: [
      {
        dimension: "Harvestdata.clientName",
        operator: clients[clientFilter] !== "All" ? "contains" : "set",
        values: [`${clients[clientFilter].toLowerCase()}`],
      },
      {
        dimension: "Harvestdata.billable",
        operator: billable ? "equals" : "set",
        values: [billable ? billable.toString() : "All"],
      }
    ],
  };

  const taskTypeQuery = {
    measures: ["Harvestdata.totalHours"],
    timeDimensions: [
      {
        dimension: "Harvestdata.spentDate",
        dateRange: dateRange,
      },
    ],
    filters: [
      {
        dimension: "Harvestdata.clientName",
        operator: clients[clientFilter] !== "All" ? "contains" : "set",
        values: [`${clients[clientFilter].toLowerCase()}`],
      },
      {
        dimension: "Harvestdata.billable",
        operator: billable ? "equals" : "set",
        values: [billable ? billable.toString() : "All"],
      }
    ],
    dimensions: [clients[clientFilter].toLowerCase() === "sis/phd" ?  "Harvestdata.taskName" : "Harvestpodio.requestType"],
  };

  const projecTypeQuery = {
    measures: ["Harvestdata.totalHours"],
    timeDimensions: [
      {
        dimension: "Harvestdata.spentDate",
        dateRange: dateRange,
      },
    ],
    filters: [
      {
        dimension: "Harvestdata.clientName",
        operator: clients[clientFilter] !== "All" ? "contains" : "set",
        values: [`${clients[clientFilter].toLowerCase()}`],
      },
      {
        dimension: "Harvestdata.billable",
        operator: billable ? "equals" : "set",
        values: [billable ? billable.toString() : "All"],
      }
    ],
    dimensions: ["Harvestdata.projectName"],
  };

  const hoursPerDay = {
    timeDimensions: [
      {
        dimension: "Harvestdata.spentDate",
        granularity: "day",
        dateRange: dateRange,
      },
    ],
    measures: ["Harvestdata.totalHours"],
    dimensions: ["Harvestdata.billable"],
    filters: [
      {
        dimension: "Harvestdata.clientName",
        operator: clients[clientFilter] !== "All" ? "contains" : "set",
        values: [`${clients[clientFilter].toLowerCase()}`],
      },
      {
        dimension: "Harvestdata.billable",
        operator: billable ? "equals" : "set",
        values: [billable ? billable.toString() : "All"],
      }
    ],
  };

  const kpis = {
    measures: ["Harvestdata.totalHours"],
    dimensions: ["Harvestdata.billable"],
    timeDimensions: [
      {
        dimension: "Harvestdata.spentDate",
        dateRange: dateRange,
      },
    ],
    filters: [
      {
        dimension: "Harvestdata.clientName",
        operator: clients[clientFilter] !== "All" ? "contains" : "set",
        values: [`${clients[clientFilter].toLowerCase()}`],
      },
      {
        dimension: "Harvestdata.billable",
        operator: billable ? "equals" : "set",
        values: [billable ? billable.toString() : "All"],
      }
    ],
  };

  const ref = React.createRef();
  return (
    <div>
      <div className={classes.root} ref={ref}>
        <Grid container spacing={4} justifyContent="flex-end">
          {!dmpgClient ?
            <FormControlLabel control={
              <Switch
                checked={billable}
                onChange={handleBillable}
                color="primary"/>
            }
            label="Billable only"
            />
          :""}
          <ClientSelector clients={clients} clientFilter={clientFilter} setClientFilter={setClientFilter} />
          <DateSelector dateRange={dateRange} setDateRange={setDateRange} />
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Paper className={classes.paper} key={clients[clientFilter]} 
            style={{display: "flex", 
              flexDirection: "column", 
              justifyContent: "center",
              alignItems: "center"}}>
              <img
                className={classes.logo}
                src={`${process.env.PUBLIC_URL}/images/${clients[clientFilter].replace(/[\/\s]/g,'_').toLowerCase()}.png`}
                onError={(e) => {
                  e.target.src = '/images/dmpg_logo.png' // some replacement image
               }}
                alt={clients[clientFilter]}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.paper}>
               <KPIChart query={kpis} />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Typography className={classes.title} color="textPrimary" gutterBottom variant="body2"
              style={{
                paddingTop: 16,
                paddingLeft: 24,
                fontSize: "16px"
              }}>
                Hours / day
              </Typography>
              <LineChart query={hoursPerDay} 
              style={{
                marginTop: -42,
              }}/>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <DoughnutChart query={projecTypeQuery} />
          </Grid>
          <Grid item xs={6}>
            <BarChart query={taskTypeQuery} />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.content}>
              <Table
                sorting={sorting}
                setSorting={setSorting}
                query={entriesTableQuery}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Dashboard;
